.webiny-pb-page-element-input{
    &__field{
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        font-size: 1rem;
        border:2px solid $webiny-pb-theme-background;
        background-color: $webiny-pb-theme-background;
        &:focus{
            border-color: $webiny-pb-theme-primary;
            outline: none;
        }
    }
    &__helper-text{
        font-size: 0.8rem;
        margin-left: 2px;
    }
}
