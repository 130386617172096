@mixin mobile {
  @media (min-width: 0px) and (max-width: #{$webiny-pb-theme-tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$webiny-pb-theme-tablet-width}) and (max-width: #{$webiny-pb-theme-desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$webiny-pb-theme-desktop-width}) {
    @content;
  }
}

@mixin desktop-and-tablet {
  @media (min-width: #{$webiny-pb-theme-tablet-width}) {
    @content;
  }
}
