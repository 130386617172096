.webiny-pb-page{
  min-height: 100vh;
  position: relative;
  padding-bottom: 100px;
}

.webiny-pb-page-document{
  background-color: var(--webiny-pb-theme-surface);
  min-height: calc(100vh + 150px);
  max-width: 100%;

  // fixes the overflow created by the animation library (fix pending)
  // https://github.com/michalsnik/aos/issues/416
  overflow: hidden;
  width: 100vw;

  @include desktop-and-tablet{
    min-height: calc(100vh - 230px);
  }
}

// base css class that applies to all elements created by the page editor
.webiny-pb-base-page-element-style{
  padding: var(--mobile-padding-top) var(--mobile-padding-right) var(--mobile-padding-bottom) var(--mobile-padding-left);
  margin: var(--mobile-margin-top) var(--mobile-margin-right) var(--mobile-margin-bottom) var(--mobile-margin-left);

  @include mobile{
    background-size: contain !important;
  }

  @include desktop-and-tablet {
    padding: var(--desktop-padding-top) var(--desktop-padding-right) var(--desktop-padding-bottom) var(--desktop-padding-left);
    margin: var(--desktop-margin-top) var(--desktop-margin-right) var(--desktop-margin-bottom) var(--desktop-margin-left);
  }
}

.hide-on-mobile{
  @include mobile{
    display: none;
  }
}

.hide-on-desktop-and-tablet{
  @include desktop-and-tablet{
    display: none;
  }
}
