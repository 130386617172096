.webiny-pb-section-footer {
  background-color: $webiny-pb-theme-background;
  height: 100px;
  position: absolute;
  bottom: 0;
  width: 100%;
  &__wrapper{
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    @include desktop-and-tablet{
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__logo {
    flex: 1;
    align-items: center;
    display: flex;
    a{
      display: inline-block;
      line-height: 80%;
      img{
        max-height: 25px;
        margin-right: 10px;
      }
    }
    @include mobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img{
        margin-bottom: 5px;
      }
    }
  }
  &__social {
    text-align: right;
    a{
      display: inline-block;
      svg{
        height: 25px;
        margin-left: 10px;
        opacity: .5;
        transition: opacity .3s;
        color: $webiny-pb-theme-on-background;
        &:hover{
          opacity: 1;
        }
      }
    }
    @include desktop-and-tablet{
      flex: 1;
    }
    @include mobile{
      height: 25px;
      padding-bottom: 10px;
    }
  }
}

// adjust some styles in case when we render the full layout inside the page editor
.webiny-pb-editor-content-preview{
  .webiny-pb-section-footer {
    padding: 0 10px;
    box-sizing: border-box;
    position: relative;
  }
}
